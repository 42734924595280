import Home from './pages/Home';
import WhyUs from './pages/Drivers/WhyUs';
import SearchChargingStations from './pages/Drivers/SearchChargingStations';
import ResourcesGuidances from './pages/Drivers/ResourcesGuidances';
import DriverSupport from './pages/Drivers/DriverSupport';
import WhyUsSolutions from './pages/Business/WhyUsSolutions';
import BusinessResourcesGuidances from './pages/Business/BusinessResourcesGuidances';
import BusinessSupport from './pages/Business/BusinessSupport';
import WhoWeAre from './pages/AboutUs/WhoWeAre';
import MissionVision from './pages/AboutUs/MissionVision';
import ContactUs from './pages/AboutUs/ContactUs';
//import DriverProfile from './pages/Users/Drivers/DriverProfile';
//import PropertyProfile from './pages/Users/Business/PropertyProfile';
import FindChargers from './pages/Users/Drivers/FindChargers';
import DriverChargingHistory from './pages/Users/Drivers/UsageHistory';
import BusinessChargingHistory from './pages/Users/Business/ChargingHistory';
import BillingInfo from './pages/Users/Business/BillingInfo';
import DriverSettings from './pages/Users/Drivers/DriverSettings';
import PropertySettings from './pages/Users/Business/PropertySettings';
import GeneralSupport from './pages/GeneralSupport';
import SignIn from './pages/Users/Auth/SignIn';
import SignUp from './pages/Users/Auth/SignUp';

import TwoFactorAuth from './pages/Users/Auth/TwoFactorAuth';
import ChargerDetails from './pages/Users/Chargers/ChargerDetails';
//import DriverChargingHistory from './pages/Users/Chargers/DriverChargingHistory';
import UserProfile from './pages/Users/UserProfile';
import ChargerRegistration from './pages/Users/Chargers/ChargerRegistration';
import ChargerQRCodeDisplay from './pages/Users/Chargers/ChargerQRCodeDisplay';
import ManageGroupsAndChargers from './pages/Users/Chargers/ManageGroupsAndChargers';
import ChargerOptions from './pages/Users/Chargers/ChargerOptions';
import DriverDashboard from './pages/Users/Drivers/Dashboard';
import PropertyManagerDashboard from './pages/Users/Business/Dashboard';
import ContactUsForm from './pages/contactUs';
import EmailVerification from './pages/Users/Auth/EmailVerification';
import PhoneVerification from './pages/Users/Auth/PhoneVerification';
import Reset from './pages/Users/Auth/Reset';
import MessagesPage from './pages/Messages';
import ACHTransferPage from './pages/Users/Business/Payments';

const routes = [
  { path: '/', exact: true, component: Home },
  { path: '/ev-drivers/why-us', component: WhyUs },
  { path: '/ev-drivers/search-charging-stations', component: SearchChargingStations },
  { path: '/ev-drivers/resources-guidances', component: ResourcesGuidances },
  { path: '/ev-drivers/support', component: DriverSupport },
  { path: '/business-owners/why-us-solutions', component: WhyUsSolutions },
  { path: '/business-owners/resources-guidances', component: BusinessResourcesGuidances },
  { path: '/business-owners/support', component: BusinessSupport },
  { path: '/about-us/who-we-are', component: WhoWeAre },
  { path: '/about-us/mission-vision', component: MissionVision },
  { path: '/about-us/contact-us', component: ContactUs },
  { path: '/users/drivers/driver-profile', component: UserProfile },
  { path: '/users/business/property-profile', component: UserProfile },
  { path: '/users/drivers/find-chargers', component: FindChargers },
  { path: '/users/business/charging-history', component: BusinessChargingHistory },
  { path: '/users/business/billing-info', component: BillingInfo },
  { path: '/users/drivers/driver-settings', component: DriverSettings },
  { path: '/users/business/property-settings', component: PropertySettings },
  { path: '/support', component: GeneralSupport },
  { path: '/signin', component: SignIn },
  { path: '/signup', component: SignUp },
  { path: '/reset', component: Reset },
  { path: '/email-verification', component: EmailVerification },
  { path: '/phone-verification', component: PhoneVerification },
  { path: '/2fa', component: TwoFactorAuth },
  { path: '/users/drivers/driver-charging-history', component: DriverChargingHistory},
  { path: '/users/charger_registration', component: ChargerRegistration},
  { path: '/users/charger_registration_display', component: ChargerQRCodeDisplay},
  { path: '/users/charger_manage_groups_and_chargers', component: ManageGroupsAndChargers},
  { path: '/users/charger_options', component: ChargerOptions},
  { path: '/users/driver/dashboard', component: DriverDashboard},
  { path: '/users/business/dashboard', component: PropertyManagerDashboard},
  { path: '/contact_us', component: ContactUsForm},
  { path: '/users/messages', component: MessagesPage},


  { path: '/authentication/email_verification', component: EmailVerification},
  { path: '/authentication/phone_verification', component: PhoneVerification},
  { path: '/authentication/reset-password/basic', component: Reset},

  { path: '/users/business/payments', component: ACHTransferPage },
  


  
  { path: '/users/user-profile', component: UserProfile },
  { path: '/charger/:chargerid', component: ChargerDetails },  // Dynamic route for charger details

];

export default routes;
