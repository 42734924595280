import React, { useEffect, useState } from 'react';
import { PlaidLink } from 'react-plaid-link';
import getEnvConfig from '../../../../config';
import Sidebar from '../../../../Components/Sidebar';
import { fetchToken } from '../../../../Auth';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';

const ACHTransferPage = () => {
  const { backendUrl } = getEnvConfig();
  const [balance, setBalance] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [linkToken, setLinkToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [publicToken, setPublicToken] = useState(null);
  const [transferAmount, setTransferAmount] = useState(50.0);
  const token = fetchToken();

  useEffect(() => {
    const fetchBalanceAndLinkToken = async () => {
      try {
        const balanceResponse = await fetch(`${backendUrl}/payments/balance`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_token: token })
        });
        const balanceData = await balanceResponse.json();
        setBalance(balanceData.balance);
        setPaymentHistory(balanceData.payments);

        const linkResponse = await fetch(`${backendUrl}/payments/plaid_link_token`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_token: token })
        });
        const linkData = await linkResponse.json();
        setLinkToken(linkData.link_token);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchBalanceAndLinkToken();
  }, [backendUrl, token]);

  const onSuccess = async (publicToken, metadata) => {
    setPublicToken(publicToken);
    try {
      await fetch(`${backendUrl}/payments/connect_account`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          public_token: publicToken,
          user_token: token
        })
      });
      setAccountId(metadata.accounts[0].id);
      alert("Account successfully connected!");
    } catch (error) {
      console.error('Error connecting account:', error);
    }
  };

  const initiateTransfer = async () => {
    try {
      setIsLoading(true);
      await fetch(`${backendUrl}/payments/create_transfer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_token: token,
          public_token: publicToken,
          amount: transferAmount,
          account_id: accountId
        })
      });
      
      // Update the balance and payment history immediately after initiating the transfer
      setBalance((prevBalance) => prevBalance - transferAmount);

      // Add the new transfer with "Pending" status to the payment history
      const newPayment = {
        date: new Date().toISOString().split('T')[0],  // format as YYYY-MM-DD
        amount: transferAmount,
        status: "Pending"
      };
      setPaymentHistory((prevHistory) => [newPayment, ...prevHistory]);

      alert("Transfer initiated successfully!");
    } catch (error) {
      console.error('Error initiating transfer:', error);
      alert("Transfer failed.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAmountChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value <= balance) {
      setTransferAmount(value);
    } else {
      alert("Transfer amount cannot exceed available balance.");
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <Container fluid style={{ marginLeft: '5px' }}>
        <Row className="justify-content-center my-5">
          <Col md={8}>
            <Card className="shadow-sm p-4 text-center">
              <Card.Header>
                <h3>Balance Available for Withdrawal</h3>
                <p>${balance.toFixed(2)}</p>
              </Card.Header>
              <Card.Body>
                <Form.Group as={Row} className="mb-3" controlId="transferAmount">
                  <Form.Label column sm={4}>
                    Transfer Amount
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="number"
                      value={transferAmount}
                      onChange={handleAmountChange}
                      min="0"
                      max={balance}
                      step="0.01"
                      placeholder="Enter transfer amount"
                    />
                  </Col>
                </Form.Group>
                <div className="d-flex justify-content-between align-items-center">
                  {linkToken ? (
                    <PlaidLink
                      token={linkToken}
                      onSuccess={onSuccess}
                      onExit={() => alert('Plaid connection was exited')}
                    >
                      <Button variant="primary">Connect Bank Account</Button>
                    </PlaidLink>
                  ) : (
                    <Button variant="secondary" disabled>
                      Loading...
                    </Button>
                  )}
                  <Button
                    variant="success"
                    onClick={initiateTransfer}
                    disabled={!accountId || isLoading}
                  >
                    {isLoading ? "Processing..." : "Initiate Transfer"}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center my-5">
          <Col md={8}>
            <Card className="shadow-sm p-4">
              <Card.Header>
                <h4>Payment History</h4>
              </Card.Header>
              <Card.Body>
                <ul>
                  {paymentHistory.map((payment, index) => (
                    <li key={index}>
                      {payment.date} - ${payment.amount.toFixed(2)} - {payment.status}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ACHTransferPage;
