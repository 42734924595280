import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Table } from 'react-bootstrap';
import Sidebar from '../../Components/Sidebar';
import { fetchToken } from '../../Auth';
import getEnvConfig from '../../config';

const MessagesPage = () => {
    const { backendUrl } = getEnvConfig();
    const [messages, setMessages] = useState([]);
    const [modifiedMessages, setModifiedMessages] = useState([]);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [messageSource, setMessageSource] = useState("public.messages");
    const token = fetchToken();

    useEffect(() => {
        const checkSuperUserStatus = async () => {
            try {
                const response = await fetch(`${backendUrl}/users/check_superuser`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_token: token }),
                });
                const data = await response.json();
                if (data.super_user) {
                    setIsSuperUser(true);
                    fetchMessages("public.messages");
                } else {
                    alert('You are not authorized to view this page.');
                }
            } catch (error) {
                console.error('Error checking superuser status', error);
            }
        };

        checkSuperUserStatus();
    }, [backendUrl, token]);

    const fetchMessages = async (source) => {
        try {
            const response = await fetch(`${backendUrl}/support/unseen`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ source })
            });
            const data = await response.json();
            // Ensure that data is an array
            setMessages(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };

    const handleSourceChange = (e) => {
        const selectedSource = e.target.value;
        setMessageSource(selectedSource);
        fetchMessages(selectedSource);
    };

    const handleInputChange = (id, field, value) => {
        const updatedMessages = messages.map((msg) =>
            msg.id === id ? { ...msg, [field]: value } : msg
        );
        setMessages(updatedMessages);

        if (!modifiedMessages.some((msg) => msg.id === id)) {
            setModifiedMessages([...modifiedMessages, { id }]);
        }
    };

    const saveChanges = async () => {
        const updates = messages.filter((msg) =>
            modifiedMessages.some((modMsg) => modMsg.id === msg.id)
        );
        try {
            await fetch(`${backendUrl}/support/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ source: messageSource, message_updates: updates }),
            });
            alert('Changes saved successfully');
            setModifiedMessages([]);
            fetchMessages(messageSource); // Refresh messages
        } catch (error) {
            console.error('Error saving changes', error);
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <Container fluid style={{ marginLeft: '5px' }}>
                {isSuperUser && (
                    <>
                        <Row className="justify-content-center my-5">
                            <Col md={8}>
                                <Card className="shadow-sm p-4">
                                    <Card.Header className="text-center">
                                        <h3>Messages</h3>
                                        <Form.Select
                                            aria-label="Message Source"
                                            onChange={handleSourceChange}
                                            value={messageSource}
                                            className="my-3"
                                        >
                                            <option value="public.messages">Public Messages</option>
                                            <option value="public.messages_business_general">Business General</option>
                                            <option value="public.messages_driver_general">Driver General</option>
                                        </Form.Select>
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Message</th>
                                                    <th>Seen</th>
                                                    <th>Replied</th>
                                                    <th>Reply</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {messages.length > 0 ? (
                                                    messages.map((msg) => (
                                                        <tr key={msg.id} className={modifiedMessages.some((modMsg) => modMsg.id === msg.id) ? 'table-warning' : ''}>
                                                            <td>{msg.name}</td>
                                                            <td>{msg.email}</td>
                                                            <td>{msg.phonenumber}</td>
                                                            <td>{msg.message}</td>
                                                            <td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={msg.seen}
                                                                    onChange={(e) => handleInputChange(msg.id, 'seen', e.target.checked)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={msg.replied}
                                                                    onChange={(e) => handleInputChange(msg.id, 'replied', e.target.checked)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={msg.reply || ''}
                                                                    onChange={(e) => handleInputChange(msg.id, 'reply', e.target.value)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr><td colSpan="7" className="text-center">No messages found</td></tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                                <Button variant="primary" className="mt-3 w-100" onClick={saveChanges} disabled={modifiedMessages.length === 0}>
                                    Save Changes
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </div>
    );
};

export default MessagesPage;
